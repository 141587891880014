import React from 'react';
import moment from 'moment';
import GoogleMapReact from 'google-map-react';
import Close from '../../../assets/svg/close.svg';
import BGTopDeco from '../../../assets/svg/single-event-img-down.svg';
import { GOOGLE_MAPS_API_KEY } from '../../../constants';

const MarkerComponent = ({ text }) => (
    <div className="marker">
        <i className="fas fa-map-marker-alt primary-color" style={{ fontSize: '32px' }} ></i>
    </div>
);

const style = show => show ? { display: 'block', opacity: 1 } : { display: 'none', opacity: 0 };

export const EventModal = ({ name, location, starts, ends, description, thumbnailImage, gmap, show, handleClose }) =>
    <div className="modal fade" tabIndex="-1" role="dialog" style={style(show)}>
        <div className="modal-dialog modal-dialog-scrollable modal-xl" role="document">
            <div className="modal-content">
                <div className="modal-body row">
                    <div className="col-12">
                        {thumbnailImage && <img src={thumbnailImage.url} alt={thumbnailImage.alt} />}
                        <div className="deco-top-bg" style={{ backgroundImage: `url(${BGTopDeco})` }}></div>
                    </div>
                    <div className="col-10">
                        <h2 className="primary-color mb-3">{name}</h2>
                        <p className="secondary-color mb-3"><i className="fas fa-map-marker-alt secondary-color"></i> {location}</p>
                        <p className="secondary-color mb-3"><i className="fas fa-clock secondary-color"></i> {moment(starts, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A')} - {moment(ends, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A')}</p>
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                    <div className="col-2">
                        <div className="primary-bg-color date-container">
                            <div className="date-row">
                                <span className="default-secondary-color number">{moment(starts, 'YYYY-MM-DD HH:mm:ss').format('DD')}</span>
                                <span className="default-secondary-color month">{moment(starts, 'YYYY-MM-DD HH:mm:ss').format('MMM')}</span>
                            </div>
                        </div>
                    </div>
                    {gmap.lat === '' && gmap.lng === '' &&
                        <div className="col-12 mt-90">
                            <div className="google-maps-container">
                                    <GoogleMapReact bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }} zoom={15} center={{ lat: parseFloat(gmap.lat), lng: parseFloat(gmap.lng) }} >
                                        <MarkerComponent lat={gmap.lat} lng={gmap.lng} text="Event location" />
                                    </GoogleMapReact>
                            </div>
                        </div>
                    }               
                </div>
                <div className="modal-footer">
                    <div onClick={handleClose}>
                        <img className="brand" alt="Mo-b LAB Logo" src={Close} />
                    </div>
                </div>
            </div>
        </div>
    </div>