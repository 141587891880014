import React, {Component} from 'react';
import EventsList from './eventsList';
import BGTopDeco from '../../../assets/svg/single-event-img-down.svg';

export default class EventsSection extends Component {
    render() {
        let { upcomingEventsTitle, pastEventsTitle, ...props } = this.props;
        return (
            <div id="events" className="primary-bg-color triple-inset">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="default-secondary-color mb-60 txt-align-center">{upcomingEventsTitle}</h2>
                            <div className="container-events-scroll">
                                <EventsList {...props} events={props.upcomingEvents}/>
                            </div>
                        </div>
                        <div className="col-12">
                            <h2 className="default-secondary-color mb-60 txt-align-center">{pastEventsTitle}</h2>
                            <div className="container-events-scroll">
                                <EventsList {...props} events={props.pastEvents}/>
                            </div>
                        </div>
                    </div>
                    <div className="deco-bottom-bg" style={{ backgroundImage: `url(${BGTopDeco})` }}></div>
                </div>
            </div>
        )
    }
}