import React, { Component } from 'react';
import {
    Validator,
    TextField,
    TextArea,
    ValueSetValidator,
    EmailValidator,
} from '../../input';

class Contact extends Component {

    constructor(props) {
        super(props);
        this.validator = new Validator();
    }

    register = e => {
        if (this.props.register) {
            this.props.register(this.validator.validResult());
        }
        e.preventDefault();
    }

    render() {
        let { showFormError, showFormSuccess, registrationLoading } = this.props;
        showFormError = showFormError || false;
        showFormSuccess = showFormSuccess || false;

        let sendStatus = 'initial';
        if(registrationLoading) sendStatus = 'processing';
        else if(showFormSuccess) sendStatus = 'done';

        return (
            <div className="container triple-inset mb-120 pos-rel">
                <div className="row">
                    <div className="col-6 offset-md-3">
                        <h2 className="primary-color mb-60 txt-align-center">Contact Us</h2>
                        <div className="primary-bg-color form-container">
                            <h3 className="default-secondary-color mb-3">Leave us a message</h3>
                            <div className="row">
                                <div className="col-12">
                                    {showFormError &&
                                        <div id="feedback">
                                            <div className="alert alert-danger">
                                                <p className="default-primary-color">There are some fields missing or incorrect. Please check them and try again.</p>
                                            </div>
                                        </div>
                                    }
                                    {showFormSuccess &&
                                        <div id="feedback">
                                            <div className="alert alert-success">
                                                <p className="default-primary-color">Thank you for your filling in the contact form. An email has been send to Mo-B LAB.</p>
                                            </div>
                                        </div>
                                    }
                                    <label htmlFor="fullName">Full name * <small>(required )</small></label>
                                    <TextField
                                        id='fullName'
                                        placeholder='John Doe'
                                        validator={new ValueSetValidator(this.validator)}
                                    />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="email">Email * <small>(required )</small></label>
                                    <TextField
                                        id='email'
                                        placeholder='johndoe@gmail.com'
                                        validator={new EmailValidator(this.validator)}
                                    />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="message">Message</label>
                                    <TextArea
                                        id='message'
                                        placeholder='text here...'
                                        validator={new ValueSetValidator(this.validator)}
                                    />
                                </div>
                                <div className="col-12">
                                    <button className={"btn btn-secondary " + sendStatus} onClick={this.register} disabled={(['processing', 'done'].includes(sendStatus))}>
                                        {sendStatus === 'initial' && 'Send'}
                                        {sendStatus === 'processing' && <div>Submitting<span>.</span><span>.</span><span>.</span></div>}
                                        {sendStatus === 'done' && 'Done!'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;
