import React, { Component } from 'react';
import Logo from '../../../assets/svg/logo.svg';

class Preloader extends Component {
    render() {
        return (
            <div className="preloader">
                <img src={Logo} alt="Mo-B Lab" />
                <br />
                <span>Mo-B Lab is loading...</span>
            </div>
        );
    }
}

export default Preloader;