import React, { Component } from 'react';
import { BASE_URL } from '../../constants';
import Carousel from '../subComponents/carousel/carousel';
import Logo from '../../assets/svg/logo.svg';
import EventSection from '../subComponents/eventsSection';
import Partners from '../subComponents/partners';
import ContactIndex from '../subComponents/contact/index';

import BGTopDeco from '../../assets/svg/single-event-img-down.svg';
import BGBottomDeco from '../../assets/svg/about-down.svg';
import Preloader from '../subComponents/preloader';

const AboutSection = ({ decoImages, description, title }) =>
    <React.Fragment>
        <div id="about" className="container mb-60">
            <div className="row">
                <div className="col-1" />
                <div className="col-10 txt-align-center">
                    {<img className="brand mb-5" alt="Mo-b LAB Logo" src={Logo} />}
                    <h2 className="primary-color mb-5">{title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                </div>
                <div className="col-1" />
            </div>
        </div>
        {
            decoImages && decoImages.length > 0 &&
            <div id="about-decoration" className="container-fluid">
                <div className="deco-top-bg" style={{ backgroundImage: `url(${BGTopDeco})` }}></div>
                <div className="row">
                    {decoImages.map(({ ID, url, alt }, i) =>
                        < div key={`${i}-aboout-deco-img-${ID}`} className="col reset-inset">
                            <img className="img-fluid h-490" src={url} alt={alt} />
                        </div>
                    )}
                </div>
                <div className="deco-bottom-bg" style={{ backgroundImage: `url(${BGBottomDeco})` }}></div>
            </div>
        }
    </React.Fragment>

export default class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
        };
    }

    componentDidMount() {
        fetch(`${BASE_URL}get-posts/?post-type=page&slug=${this.props.slug}`)
            .then(response => response.json())
            .then((page) => this.setState({ ...page, fetching: false }))
            .catch(error => console.error(error))
    }

    render() {
        let { fetching, carousel, about, events, partners } = this.state;
        if (fetching) return <Preloader />;
        return (
            <main id="main">
                {carousel && <Carousel carousel={carousel} />}
                {about && <AboutSection {...about} />}
                {(events.upcomingEvents || events.pastEvents) && <EventSection {...events} />}
                {/* {team.members && <Team {...team} />} */}
                {partners.partners && <Partners {...partners}/>}
                <ContactIndex />
            </main>
        );
    }
}