import React, { Component } from 'react';
import Form from '../contact/contact';
import BGDeco from '../../../assets/svg/contact.svg';

export default class ContactIndex extends Component {

    constructor(props) {
        super(props);

        this.state = {
            registrationLoading: false,
            showFormError: false,
            showFormSuccess: false,
        };
    }

    onRegister = async (results) => {
        console.log('onRegister...');
        let errors = results.map(r => r.error).filter(e => e != null);
        if (errors.length > 0) {
            console.log(`${errors.length} errors`);
            this.setState({ showFormError: true, showFormSuccess: false });
        }
        else {
            console.log('sending message...');
            let { ajaxurl, ajax_nonce } = window.ajaxVars || {};
            if (!ajaxurl || !ajax_nonce) {
                console.log(`incorrect nonce or url ${ajax_nonce}, ${ajaxurl}`);
                this.setState({ showFormError: true, showFormSuccess: false });
                return;
            }
            this.setState({ showFormError: false, showFormSuccess: true });
            let formDataStr = '';
            results.forEach((n, i) => {
                formDataStr += `${n.id}=${encodeURIComponent(n.value)}&`;
            });
            formDataStr += `security=${encodeURIComponent(ajax_nonce)}&`;
            formDataStr += 'action=handle_contact_form';

            fetch(ajaxurl, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: formDataStr
            })
                .then(response => {
                    let result = response.json();
                    console.log(result);
                })
                .then(async (responseJson) => {
                    console.log(responseJson);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(_ => {
                    this.setState({ validating: false });
                });
        }
    }

    render() {
        let { showFormError, showFormSuccess, registrationLoading } = this.state;
        return (
            <section id="contact" style={{ backgroundImage: `url(${BGDeco})` }}>
                <Form
                    register={this.onRegister}
                    info={this.info}
                    showFormError={showFormError}
                    showFormSuccess={showFormSuccess}
                    registrationLoading={registrationLoading}
                />
            </section>
        );
    }
}