import React, { Component } from 'react';

class Partners extends Component {
    render() {
        let { title, description, partners } = this.props;
        return (
            <div id="partners" className="container-fluid triple-inset">
                <div className="row triple-inset">
                    <div className="col-1" />
                    <div className="col-5">
                        <div className="row justify-content-center">
                            {partners.length > 0 && partners.map(({ link, logo, title }, i) =>
                                <div key={`${i}-parnter-${title}`} className="col-3">
                                    {link !== '' &&
                                        <a href={link} target="_blank" rel="noopener noreferrer">
                                            {logo && <img className="partner-logo" src={logo.url} alt={logo.alt} title={title} />}
                                        </a>
                                    }
                                    {link === '' &&
                                        <div>
                                            {logo && <img className="partner-logo" src={logo.url} alt={logo.alt} title={title} />}
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-1" />
                    <div className="col-4">
                        <h2 className="primary-color mb-5">{title}</h2>
                        <div dangerouslySetInnerHTML={{ __html: description }} ></div>
                    </div>
                    <div className="col-1" />
                </div>
            </div>
        );
    }
}

export default Partners;
