import React, { Component } from "react";
import Slider from "react-slick";

// IMPORT SVG'S
import DecoBGItem from '../../../assets/svg/half-circle-up.svg';

export default class Carousel extends Component {
    render() {
        let config = {
            arrows: false,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: false,
        };
        let { carousel } = this.props;
        return (
            <div id="home" className="container-fluid">
                <div className="row">
                    <div className="col reset-inset">
                        <Slider ref={slider => (this.slider1 = slider)} {...config}>
                            <div>
                                {carousel.length > 0 && carousel.map(({ ID, url }, i) =>
                                    <div key={`${i}-home-carousel-slide-${ID}`} className="carousel" 
                                    style={{ 
                                        backgroundImage: `url(${DecoBGItem}), url(${url})`,
                                    }}
                                    ></div>
                                )}
                            </div>
                        </Slider>
                    </div>
                </div>
            </div >
        );
    }
}
