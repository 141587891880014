import React, { Component } from 'react';
import CustomScroll from 'react-custom-scroll';
import { EventModal } from './eventModal';
import { BASE_URL } from '../../../constants';
import moment from 'moment';

class Events extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            ...props,
        };
        this.state.events = [];
    }

    componentDidMount() {
        let { events } = this.props;
        if (events && events.length > 0) {
            let arg = `post-type=event`;
            for (let i = 0; i < events.length; i++) {
                arg += `&id[]=${events[i]}`;
            }
            fetch(`${BASE_URL}get-posts/?${arg}`)
                .then(response => response.json())
                .then((events) => this.setState({ events, fetching: false }))
                .catch(error => console.error(error))
        }
    }

    handleShow = (event, e) => {
        this.setState({
            show: true,
            selectedEvent: event
        });
    };

    handleClose = () => {
        this.setState({
            show: false,
            selectedEvent: null
        });
    }

    render() {
        let { selectedEvent, show, events, buttonText } = this.state;
        return (
            <div className="custom-container">
                <CustomScroll heightRelativeToParent="475px" allowOuterScroll={true}>
                    {events && events.length < 1 && 
                        <p className="default-secondary-color txt-align-center">There are no events available...</p>
                    }
                    {events && events.length > 0 && events.map((event, i) =>
                        <section key={`${i}-event-${event.ID}`} className="row row-event row-eq-height">
                            <div className="col-2">
                                <div className="default-secondary-bg-color date-container">
                                    <div className="date-row">
                                        <span className="primary-color number">{moment(event.starts, 'YYYY-MM-DD HH:mm:ss').format('DD')}</span>
                                        <span className="primary-color month">{moment(event.starts, 'YYYY-MM-DD HH:mm:ss').format('MMM')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                {event.thumbnailImage && <img className="img-fluid" src={event.thumbnailImage.url} alt={event.thumbnailImage.alt} />}
                            </div>
                            <div className="col-5">
                                <h1 className="default-secondary-color as-h4">{event.name}</h1>
                                <ul className="list list-event-info">
                                    <li><i className="fas fa-clock secondary-color"></i> {moment(event.starts, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A')} - {moment(event.ends, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A')}</li>
                                    <li><i className="fas fa-map-marker-alt secondary-color"></i> {event.location}</li>
                                </ul>
                            </div>
                            <div className="col-2">
                                <button className="btn btn-primary pull-right" onClick={e => this.handleShow(event, e)}>{buttonText}</button>
                            </div>
                        </section>
                    )}
                </CustomScroll>
                {selectedEvent && <EventModal {...selectedEvent} show={show} handleClose={this.handleClose} />}
            </div>
        );
    }
}

export default Events;
