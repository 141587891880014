import React from 'react';
import {
	BrowserRouter as Router,
	Route,
} from 'react-router-dom';
import './assets/css/app.min.css';
import Header from './components/layout/header';
import Aside from './components/layout/aside';
import Footer from './components/layout/footer';
import HomePage from './components/screens/homePage';

export default function App() {
	// let w = window.innerWidth;
	// let h = window.innerHeight;
	return (
		<Router>
			<Header />
			<Route path="/" render={(props) => <HomePage {...props} slug='home' />} exact />
			<Aside />
			<Footer />
			{/* <div id="resolution">
				Width: {w} + Height: {h}
			</div> */}
		</Router>
	)
}