import React, { Component } from 'react';
import {BASE_URL} from '../../constants';

export default class Footer extends Component {
    constructor(){
        super();
        this.state = {};
    }
    componentDidMount() {
        fetch(`${BASE_URL}get-layout/?post-type=options-footer`)
            .then(res => res.json())
            .then(footer => this.setState({ ...footer, fetching: false }));
    }
    render() {
        let {company_name} = this.state;
        return (
            <footer id="footer" className="primary-bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-4 offset-md-4">
                            <p className="default-secondary-color txt-align-center">&copy; Copyright {new Date().getFullYear() + ' ' + company_name}</p>
                        </div>
                        <div className="col-4">
                            <p className="default-secondary-color align-right">Engineered by: <a href="https://cr38te.com" className="secondary-color" target="_blank" rel="noopener noreferrer">CR38TE</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}