import React, { Component } from 'react';
import { BASE_URL } from '../../constants';
import { Link, Events, scrollSpy } from 'react-scroll';
import Logo from '../../assets/svg/logo.svg';
import FuturaLogo from '../../assets/svg/futura.svg';
import GOALogo from '../../assets/svg/gobierno-di-aruba.svg';
import OCTALogo from '../../assets/svg/octa.svg';

export default class Header extends Component {
    constructor() {
        super();
        this.state = {
            mobileNavVisible: true,
        };
    }

    componentDidMount() {
        fetch(`${BASE_URL}get-layout/?post-type=options-header`)
            .then(res => res.json())
            .then(navItems => this.setState(_ => {
                return { nav: navItems.menu_items };
            }));

        Events.scrollEvent.register('begin', () => console.log("begin", arguments));
        Events.scrollEvent.register('end', () => console.log("end", arguments));
        scrollSpy.update();
    };

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    };

    handleSetActive(to) {
        //console.log(to);
    };

    renderMobileNav() {
        if(!this.state.mobileNavVisible) {
            this.setState({mobileNavVisible: true});
        } else {
            this.setState({mobileNavVisible: false});
        }
    }

    render() {
        let { nav } = this.state;
        if (!nav) return null;
        const visibility = this.state.mobileNavVisible ? {} : { 'display':'block' };
        return (
            <header id="header">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <nav role="navigation">
                                <div className="mobile-nav" id="mobile-nav"
                                    onClick={() => this.renderMobileNav()}
                                >
                                    <i className="fas fa-bars"></i>
                                    Menu
                                </div>
                                <ul className="nav" >
                                    {nav.length > 0 && nav.map(({ type, scroll_to_id, title }, i) =>
                                        <li key={`${i}-nav-item-${scroll_to_id}`} className="nav-item" style={visibility}>
                                            {(type === 'Link') &&
                                                <Link activeClass={`${(window.location.hash === '#' + scroll_to_id) ? 'active' : 'active'} `} to={scroll_to_id} onSetActive={this.handleSetActive}
                                                    hashSpy={true}
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-85}
                                                    duration={350} 
                                                >
                                                    {title}
                                                </Link>
                                            }{(type === 'Logo') && <h1><img className="brand" src={Logo} alt="Mo-B Lab" /></h1>}
                                        </li>
                                    )}
                                    <li></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <ul id="main-partner">
                    <li>
                        <img src={FuturaLogo} alt={'Futura'} />                              
                    </li>  
                    <li>
                        <img src={GOALogo} alt={'GOALogo'} />    
                    </li>  
                    <li>
                        <img src={OCTALogo} alt={'OCTA'} />
                    </li>                                    
                </ul>
            </header>
        );
    }
}