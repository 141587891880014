import React, { Component } from 'react';
import { BASE_URL } from '../../constants';
import Logo from '../../assets/svg/logo.svg';
import BGTopDeco from '../../assets/svg/half-circle-up-purple.svg';
import FuturaLogo from '../../assets/svg/futura.svg';
import GOALogo from '../../assets/svg/gobierno-di-aruba.svg';
import OCTALogo from '../../assets/svg/octa.svg';

export default class Aside extends Component {
    constructor() {
        super();
        this.state = {
            fetching: true,
        };
    }
    componentDidMount() {
        fetch(`${BASE_URL}get-layout/?post-type=options-aside`)
            .then(res => res.json())
            .then(aside => this.setState({ ...aside, fetching: false }));
    }
    render() {
        let { fetching, social_links, contact } = this.state;
        if (fetching) return null;
        return (
            <aside id="complementary" className="primary-bg-color triple-inset">
                <div className="aside-deco" style={{ backgroundImage: `url(${BGTopDeco})` }}></div>
                <div className="container">
                    <div className="row">
                        <img className="brand" src={Logo} alt="Mo-B LAB Logo" />
                        {social_links &&
                            <ul className="list list-social">
                                {social_links.map(({ link, title, type }, i) =>
                                    <li key={`social_link-${type}-${i}`} >
                                        <a href={link} target="_blank" rel="noopener noreferrer" title={title}><i className={`fab fa-${type} default-secondary-color`}></i></a>
                                    </li>
                                )}
                            </ul>
                        }
                    </div>
                    {contact && contact.length > 0 &&
                        <div className="row">
                            {contact.map(function ({ type, info, info_multiline, email, }, i) {
                                if (type === 'text') return (<div key={`text-${i}`} className="col"><p className="default-secondary-color">{info}</p></div>);
                                if (type === 'address') return (<div key={`address-${i}`} className="col"><p className="default-secondary-color"><i className="fas fa-map-marker-alt secondary-color"></i>{info}</p></div>);
                                if (type === 'addressMulti') return (<div key={`address-${i}`} className="col"><p className="default-secondary-color"><i className="fas fa-map-marker-alt secondary-color"></i>{info_multiline}</p></div>);
                                if (type === 'phone') return (<div key={`phone-${i}`} className="col"><p className="default-secondary-color"><i className="fas fa-phone secondary-color"></i>{info}</p></div>);
                                if (type === 'mobile') return (<div key={`mobile-${i}`} className="col"><p className="default-secondary-color"><i className="fas fa-mobile secondary-color"></i>{info}</p></div>);
                                if (type === 'fax') return (<div key={`fax-${i}`} className="col"><p className="default-secondary-color"><i className="fas fa-fax secondary-color"></i>{info}</p></div>);
                                if (type === 'email') return (<div key={`email-${i}`} className="col"><p className="default-secondary-color"><i className="fas fa-envelope secondary-color"></i><a href={`mailto:${email}`}>{email}</a></p></div>);
                                if (type === 'openingHours') return (<div key={`openingHours-${i}`} className="col"><p className="default-secondary-color"><i className="far fa-clock secondary-color"></i>{info}</p></div>);
                                if (type === 'building') return (<div key={`building-${i}`} className="col"><p className="default-secondary-color"><i className="fas fa-building secondary-color"></i>{info}</p></div>);
                                return null;
                            })}
                        </div>
                    }
                    <div className="row">
                        <div className="col txt-align-center">
                            <ul id="main-partner">
                                <li>
                                    <img src={FuturaLogo} alt={'Futura'} />                              
                                </li>  
                                <li>
                                    <img src={GOALogo} alt={'GOALogo'} />    
                                </li>  
                                <li>
                                    <img src={OCTALogo} alt={'OCTA'} />
                                </li>                                    
                            </ul>
                        </div>
                    </div>
                </div>
            </aside>
        );
    }
}